import { tns } from 'tiny-slider/src/tiny-slider';

function initSliders() {
  const container = document.querySelector('[data-slider-container]');

  if (container) {
    const imagesSlider = tns({
      container: container.querySelector('[data-slider-image]'),
      items: 1,
      slideBy: 1,
      nav: false,
      speed: 1500,
      controls: false,
      loop: false,
      mode: 'gallery',
      animateIn: 'animate_in',
      animateOut: 'animate_out',
      animateNormal: 'animate_normal',
      rewind: true,
      autoplayButtonOutput: false,
      preventActionWhenRunning: true,
      autoplay: true,
      autoplayTimeout: 7000,
    });

    const textSlider = tns({
      container: container.querySelector('[data-slider-text]'),
      items: 1,
      slideBy: 1,
      nav: false,
      speed: 1500,
      controls: false,
      loop: false,
      mode: 'gallery',
      rewind: true,
      autoplayButtonOutput: false,
      preventActionWhenRunning: true,
      autoplay: true,
      autoplayTimeout: 7000,
    });
  }
}

document.addEventListener('DOMContentLoaded', () => {
  initSliders();
});